.login-page {
  margin-top: 20vh;
}

.login-page img {
  width: 70px;
}

.otp-content{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-modal,
.otp-modal .ant-modal-content {
  height: 100vh;
  width: 100vw;
  margin: 0;
  top: 0;
}
.otp-modal .ant-modal-body {
  height: calc(100vh - 110px);
}