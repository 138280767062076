@import "./schedule.css";
@import "antd/dist/antd.variable.min.css";

@font-face {
  font-family: "NewSansArabic";
  src: url("./assets/fonts/NeoSansArabic.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "NewSansArabicBold";
  src: url("./assets/fonts/NeoSansArabicBold.ttf");
  font-weight: bold;
}

* {
  font-family: "NewSansArabic";
  margin: 0;
  padding: 0;
}
.app-header {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 5px 20px -7px #dedede;
}
.app-page {
  margin-top: 100px;
}

.app-menu {
  /* min-width: 40%; */
  display: flex;
  justify-content: center;
}


.app-modal .ant-modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}

.app-modal .ant-modal-footer {
  border-top: none;
}

.app-modal .ant-form-item {
  margin: 0;
}

.distanc-list .ant-form-item-label {
  padding: 0;
  margin: 0;
}

.app-media-image {
  width: 100%;
  border-radius: 5px;
  height: 10vw;
  object-fit: cover;
}

.app-media-link {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10vw;
  overflow: hidden;
  font-size: 14px;
  margin-top: 10px;
}

.table-link .ant-space-item:first-child {
  text-overflow: ellipsis;
  width: 15vw;
  overflow: hidden;
  font-size: 14px;
}


.search-box {
  margin-top: 10px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e1e1e1;
}

.search-box .search-input {
  display: flex;
  height: 32px;
  overflow: hidden;
}

.search-box .search-input input {
  flex: auto;
  border: none;
  padding: 0 12px;
  outline: none;
}

.search-box .search-input input::placeholder {
  color: #b8b8b8;
}

.search-box .search-input .anticon {
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b8b8b8;
}

.search-box ul {
  padding: 0;
  list-style-type: none;
  border-top: 1px solid #e1e1e1;
  border-left: none;
  border-right: none;
  margin: 0;
}

.search-box ul li {
  padding: 4px 12px;
  border-bottom: 1px solid #e1e1e1;
  color: #b8b8b8;
  cursor: pointer;
  transition: 0.2s;
}

.search-box ul li:last-child {
  border: none;
  margin-bottom: 1px;
}

.search-box ul li:hover {
  color: #24292e;
}

.search-box ul li:active {
  opacity: 0.4;
}


/* Gloable */

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  transform: skewX(-2deg);
}

.app-container {
  margin: auto;
  max-width: 1600px;
  min-width: 800px;
  padding: 0 30px;
}

.app-space-between {
  display: flex;
  justify-content: space-between;
}

.filters-container {
  margin: 15px 0px;
}

.app-justify-right {
  justify-content: right;
}

.app-justify-left {
  justify-content: left;
}

.app-justify-center {
  justify-content: center;
}

.app-flex-column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 2px;
}

.color-item {
  width: 100%;
  height: 2vw;
  border-radius: 3px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: 0.3s;
}

.color-item span.active {
  width: 10px;
  height: 10px;
  background-color: #0000ffba;
  border-radius: 1px;
  margin: 3px;
  position: absolute;
}

.color-item:hover {
  border-color: #0000ffba !important;
}

.color-item:active {
  opacity: 0.6;
}

.resturant-constroll-form {
  position: absolute;
  bottom: 35px;
  right: 25px;
  left: 25px;
}

/* Media */

@media only screen and (min-width: 1750px) {
  .app-container {
    max-width: 1700px;
  }
}

.styles-module_header__10uIZ .styles-module_cell__sVJZY {
  font-size: 14px;
}

.styles-module_header__10uIZ {
  background-color: #fff;
}

.styles-module_header__10uIZ {
  box-shadow: none;
}

.custome-schedule .ant-picker-suffix {
  display: none;
}

.tauchable {
  cursor: pointer;
}

.disabled {
  opacity: 0.4;
}

.tauchable:hover {
  opacity: 0.8;
}

.tauchable:active {
  opacity: 0.4;
}

.steps-content {
  width: 100%;
  display: block;
  min-height: 550px;
  padding-top: 20px;
}

.ant-list-grid .ant-col>.ant-list-item {
  margin-bottom: 10px;
}

.img-placeholder {
  background-color: #f6f6f6;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  border-radius: 4px;
}

.filter-modal .ant-modal-footer {
  padding: 34px;
  padding-top: 0;
  text-align: left;
}

.filter-wrapper {
  display: flex;
  flex-direction: row;
  padding: 4px 0px;

}

.item {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 24px;
}

.spin-wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-modal #previewMap {
  overflow: hidden;
  border-radius: 10px;
}

.preview-modal iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.preview-modal iframe .app-sidebar {
  box-shadow: none !important;
}

.mobile-card {
  width: 100%;
  height: 100%;
  background: #000;
  padding: 10px;
  border-radius: 10px;
}

.mobile-card .content {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.app-order-details .header {
  display: flex;
  justify-content: space-between;
}

.app-order-details .body {
  margin-top: 20px;
}

.app-order-details .body .ant-list-item-meta-title {
  margin: 0;
}

.app-order-details .body .ant-card-body {
  padding: 10px;
}

.app-order-details .ant-card-head {
  padding: 0 10px;
}

.app-order-details .body .ant-card-meta-title {
  margin: 0;
}

.app-order-details .body .ant-typography {
  margin: 0;
}

.order-view-page .ant-btn-sm>span {
  padding-top: 1px;
}

.order-view-page .sub-title {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-view-page .order-info {
  background-color: #fff;

  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 6px;
}

.order-view-page .status-history {
  background-color: #fff;

  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 6px;
  width: 100%;
}

.order-view-page .status-history>section {
  background-color: #fff;
  width: 100%;
  border-radius: 4px;
  padding: 14px 16px;
  border-bottom: 1px solid #eee;
}

.order-view-page .order-info>section {
  background-color: #fff;

  border-radius: 4px;
  padding: 14px 16px;
  border-bottom: 1px solid #eee;
  position: relative;
}

.order-view-page .order-info>section:last-child {
  border-bottom: none;
  overflow: hidden;
}

.order-view-page .biker-btn {
  display: flex;
  align-items: center;
}

.order-view-page .biker-btn svg {
  margin-right: 6px;
  padding-bottom: 4px;
}

.order-view-page .chat-btn {
  margin-bottom: -12px;
  padding: 0;
  width: 25px;
  height: 25px;
}

.invoice-list {
  background-color: #fff;

  border-radius: 4px;
}

.invoice-list ul {
  list-style-type: none;
}

.invoice-list ul li {
  padding: 14px 16px;
  width: 100%;

  border-bottom: 1px solid #eee;
}

.invoice-list ul .currency {
  font-size: 14px;
}

.invoice-list ul .qty-btn {
  border: none;
  background-color: #eee;
  font-size: 10px !important;
  border-radius: 50%;
}

.invoice-list .voucher-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 14vw;
}

.order-view-spin-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-content: center;
}

.marker {
  display: block;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}


#components-dropdown-demo-arrow .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}

.ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
  margin-right: 0;
  margin-bottom: 8px;
  margin-left: 8px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f6f6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.order-product .ant-select-selector {
  padding: 0 !important;
}

.order-product .item-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 75px;
  align-items: end;
}

.order-product .item-right p {
  margin: 0;
}

.rings-alert {
  animation-name: alertAnim;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
}

@keyframes alertAnim {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.6) rotate(20deg);
  }

  100% {
    transform: scale(0.8);
  }
}

.auth-radio .ant-checkbox-wrapper {
  padding: 5px 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 0px;
}

.auth-radio .ant-checkbox-wrapper-checked {
  color: #0000ff;
  border-color: #0000ff;
}


.sub-products-items .sub-content{
  margin-bottom: 20px;
  margin-top: 10px;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 4px;
}


.ant-btn-sm,
.ant-btn-icon-only.ant-btn-sm{
  border-radius: 4px !important;
}

.bulk-voucher-modal .ant-descriptions-bordered .ant-descriptions-item-label{
  color: #666;
}

.table-row-color{
  background-color: #dcdcdcf4;
}

.images-modal{
  display: flex;
  gap: 8px;
  overflow: auto;
  width: 100%;
  padding: 1px;
}

.image-container{
  background-color: rgba(230, 230, 230, 0.729);
  padding: 10px;
  border: 1px solid rgba(204, 204, 204, 0.638);
  border-radius: 5px;
  
}
.ant-tree .ant-tree-node-content-wrapper{
  display: flex;
}

.customer-review-cell {
  max-width: 400px; 
  overflow-x: auto;
  white-space: nowrap;
}

.customer-review-cell::-webkit-scrollbar {
  width: 10px; 
}

.customer-review-cell::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.customer-review-cell::-webkit-scrollbar-thumb {
  background: #888;
}

