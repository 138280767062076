.step-line {
  height: 4px;
  border-radius: 5px;
  width: 100%;
  background-color: #f3f3f3;
  display: flex;
  overflow: hidden;
}

.step-line span.step-line-select {
  transition: 0.2s;
  background-color: #0000ff;
}


.vendor-action-item {
  padding: 8px 10px;
  border-bottom: 1px solid #eee;
  user-select: none;
}

.vendor-action-item {
  padding: 8px 10px;
  border-bottom: 1px solid #eee;
  user-select: none;
}

.vendor-action-item-danger {
  color: rgb(247, 39, 39);
}

.vendor-action-item:hover {
  opacity: 0.8;
  font-weight: bold;
}

.vendor-action-item:last-child {
  border-bottom: none;
}